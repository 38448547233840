import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/ActionJail/layout/Layout.vue';

export default [
  {
    path: '/action_jail',
    component: LAYOUT.base,
    redirect: '/action_jail/actions',
    children: [
      {
        path: 'actions',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Actions',
            component: () => import(/* webpackChunkName: 'file-manager-files' */ '@/modules/ActionJail/views/ActionsTab.vue'),
          },
        ],
      },
    ],
  },
];
