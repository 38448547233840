import LAYOUT from '@/layout/layout';
import moduleLayout from '@/modules/CustomData/layout/Layout.vue';
import customDataTab from '@/modules/CustomData/views/customdata/CustomDataTab.vue';
import createTab from '@/modules/CustomData/views/customdata/CreateTab.vue';
import importTab from '@/modules/CustomData/views/customdata/ImportTab.vue';

export default [
  {
    path: '/customdata/:id',
    component: LAYOUT.base,

    children: [
      {
        path: 'models',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'CustomData',
            component: customDataTab,
          },
        ],
      },
      {
        path: 'create',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Create',
            component: createTab,
          },
        ],
      },

        {
        path: 'import',
        component: moduleLayout,
        children: [
          {
            path: '',
            name: 'Import',
            component: importTab,
          },
        ],
      },
    ],
  },
];
