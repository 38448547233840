<template>
    <v-container v-if="ajax_dialog.show" class="pt-0 pb-0">
        <v-dialog
        v-model="ajax_dialog.show"
        hide-overlay
        width="315"
        >
            <v-card v-if="ajax_dialog.loading"
                color="primary"
                dark
            >
                <v-card-text class="pt-5">
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>

            <v-card v-else>
                <v-card-title class="pt-5 justify-center">
                    <span class="headline text-center">{{ajax_dialog.title}}</span>
                </v-card-title>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout wrap>

                            <v-flex sm12>
                                <v-card-text class="headline font-weight-light">
                                    <p class="text-center">{{ajax_dialog.description}}</p>
                                </v-card-text>
                            </v-flex>

                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-flex sm12 class="text-center">
                        <v-btn color="blue darken-1" text @click="ajax_dialog.show = false">{{$t('common.close')}}</v-btn>
                    </v-flex>
                </v-card-actions>
            </v-card>


        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
    name: "AxiosPreloaderAndResponse",
    props: [],

    computed: {
        ...mapGetters([
            'ajax_dialog'
        ])

    },
}
</script>

<style>
    .text-center{
        text-align: center !important;
    }
</style>