/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'Broadcast';

let module_settings = {
  module_name,
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: getTranslate('common.settings'),
    module: module_name,
    icon: 'mdi-email',
    show: true,
    route: 'broadcast',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('topics.entity.plural'),
      content: '',
      show: true,
      type: 'topics',
      route: 'topics',
    },
    {
      tab: getTranslate('templates.entity.plural'),
      content: '',
      show: true,
      type: 'templates',
      route: 'templates',
    },
    {
      tab: getTranslate('broadcasts.entity.plural'),
      content: '',
      show: true,
      type: 'broadcast',
      route: 'broadcast',
    },
    {
      tab: getTranslate('broadcast_logs.entity.plural'),
      content: '',
      show: false,
      type: 'broadcastlogs',
      route: 'logs',
    },
    {
      tab: getTranslate('autobroadcasts.entity.plural'),
      content: '',
      show: true,
      type: 'autobroadcast',
      route: 'autobroadcast',
    },
    {
      tab: getTranslate('common.settings_tab'),
      content: '',
      show: true,
      type: 'settings',
      route: 'settings',
    },
  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Broadcast.vue',
  },
];

module.exports = { module_settings };
module.exports.default = { module_settings };
