/**
 * Created by Bezsmertnyi Ievgen on 12.09.2020.
 */

const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'CustomData';

let module_settings = {
  module_name,
};

//Корневой урл модуля
let module_alias = 'customdata';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    custom_data_show_menu_item: {
      description: 'Отображать в меню пункт Custom Data',
    },
    statistics_show_settings_menu_item: {
      description: 'Отображать настройки статистики',
    },
    statistics_show_settings_targets_tab_item: {
      description: 'Отображать цели в настройках статистики',
    },
    statistics_show_settings_funnels_tab_item: {
      description: 'Отображать воронки в настройках статистики',
    },
    //Отображение табов
    statistics_show_messengers_tab_item: {
      description: 'Отображать таб мессенджеров статистики',
    },
    custom_data_show_sections_tab_item: {
      description: 'Отображать таб секций',
    },
    statistics_show_funnels_tab_item: {
      description: 'Отображать таб воронок',
    },
    statistics_show_operators_tab_item: {
      description: 'Отображать таб операторов',
    },
  },

  back: {},
};

//Пункты в меню
module_settings.menu_items = [
  {
    title: 'Custom Data',
    module: module_name,
    alias: module_alias,
    icon: 'mdi-table',
    permits: {
      show: 'custom_data_show_menu_item',
    },
    show: true,
    route: 'customdata',
  },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [
    {
      tab: getTranslate('modules.customdata.models'),
      content: '',
      show: true,
      type: 'customdata',
      alias: '/',
      permits: { show: 'custom_data_show_sections_tab_item' },
      route: 'models',
    },
    {
      tab: getTranslate('modules.customdata.create'),
      content: '',
      show: true,
      type: 'customdata_create',
      alias: '/',
      permits: { show: 'custom_data_show_sections_tab_item' },
      route: 'create',
    },
      {
      tab: getTranslate('modules.customdata.import'),
      content: '',
      show: true,
      type: 'customdata_import',
      alias: '/',
      permits: { show: 'custom_data_show_sections_tab_item' },
      route: 'import',
    },
  ],
};

//Подключенные компоненты
// module_settings.components = [
//   {
//     name: module_name,
//     path: 'Component/CustomData.vue',
//   },
// ];

// //Левая колонка в конструкторе сценария
// module_settings.left_sidebar = {
//   sidebar_items: [
//     {
//       show: true,
//       title: 'Точка статистики',
//       icon: 'mdi-bookmark-plus-outline',
//       color: 'blue',
//       type: 'customdata',
//     },
//   ],
// };
//
// //SVG блок в конструкторе сценария
// module_settings.svg_area = {
//   svg_icons_path: {
//     statistics:
//       'M17 18V5H7v13l5-2.18L17 18m0-15a2 2 0 0 1 2 2v16l-7-3l-7 3V5a2 2 0 0 1 2-2h10m-6 4h2v2h2v2h-2v2h-2v-2H9V9h2V7z',
//   },
// };

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
