<template>
  <div>
    <v-select
      v-if="filterData.type !== ('DATE') && filterData.type !== ('SELECT') && !filterData.is_association"
      v-model="modelCondition"
      :items="filterData.conditions"
      item-text="text"
      item-value="value"
      outlined
      dense
      :label="$t('modules.customdata.filters.select_condition')"
      hide-details
      class="mb-2"
      return-object
    >
      <template v-slot:item="data">{{data.item.text}}</template>
    </v-select>

    <template v-if="filterData.is_association">
      <v-select
        outlined
        dense
        v-model="modelValue"
        :items="custom_linked_tables_values[filterData.name]"
        item-text="name"
        item-value="id"
        :menu-props="{ maxHeight: '400' }"
        :placeholder="filterData.name"
        multiple
        persistent-hint
        hide-details
        return-object
      ></v-select>
    </template>

    <template
      v-else-if="getConditionValue(modelCondition) !== 'empty' && getConditionValue(modelCondition)"
    >
      <template v-if="filterData.type === 'FLOAT' || filterData.type === 'INTEGER'">
        <template
          v-if="getConditionValue(modelCondition) === 'between' || getConditionValue(modelCondition) === 'notBetween'"
        >
          <v-text-field
            outlined
            dense
            class="custom-data__filters-number mb-2"
            v-model="arrayValues[0]"
            :label="$t('modules.customdata.filters.placeholder_from')"
            type="number"
            hide-details
          ></v-text-field>
          <v-text-field
            outlined
            dense
            class="custom-data__filters-number"
            v-model="arrayValues[1]"
            :label="$t('modules.customdata.filters.placeholder_to')"
            type="number"
            hide-details
          ></v-text-field>
        </template>
        <template v-else>
          <v-text-field
            outlined
            dense
            class="custom-data__filters-number"
            v-model="modelValue"
            :placeholder="returnFilterKeyOrLabel(filterData)"
            type="number"
            hide-details
          ></v-text-field>
        </template>

      </template>

      <template v-if="filterData.type === ('STRING') || filterData.type === 'TEXT'">
        <template v-if="getConditionValue(modelCondition) === 'multiple'">
          <v-select
            outlined
            dense
            v-model="modelValue"
            :items="filterData.values.values"
            :menu-props="{ maxHeight: '400' }"
            :label="returnFilterKeyOrLabel(filterData)"
            multiple
            hide-details
            persistent-hint
          ></v-select>
        </template>
        <template v-else>
          <v-text-field
            outlined
            dense
            class="custom-data__filters-string"
            v-model="modelValue"
            :placeholder="returnFilterKeyOrLabel(filterData)"
            hide-details
          ></v-text-field>
        </template>
      </template>

    </template>

    <template v-else-if="filterData.type === ('SELECT')">
      <v-autocomplete
        outlined
        dense
        v-model="modelValue"
        :items="filterData.values"
        :menu-props="{ maxHeight: '400' }"
        :label="returnFilterKeyOrLabel(filterData)"
        :item-text="item => item.name"
        :item-value="item => item.value"
        multiple
        hide-details
        persistent-hint
        return-object
      ></v-autocomplete>
    </template>

    <template v-else-if="filterData.type === ('DATE')">
      <v-menu
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            hide-details
            v-model="modelValue"
            :placeholder="returnFilterKeyOrLabel(filterData)"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="modelValue"
          no-title
          :locale="i18n.locale"
          scrollable
          range
        >
        </v-date-picker>
      </v-menu>
    </template>
  </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import i18n from "@/lang/i18n";

export default  {
  name: 'DynamicFilter',

  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
    selected_filters: {
      type: Object,
      default: () => {},
    },
    custom_linked_tables_values: {
      type: Object,
      default: () => {},
    },
    action_set_selected_filter_value: {
      type: String,
      default: '',
    },
    action_set_selected_filter_condition: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    i18n,
    itemModel: {
      value: null,
      condition: '',
    },
    arrayValues: [],
  }),

  computed: {
    ...mapGetters([]),

    modelValue: {
      get: function() {
        return this.selected_filters[this.filterData.name]?.value
      },
      set: function(value) {
        this.$store.dispatch(this.action_set_selected_filter_value, {
          key: this.filterData.name,
          value: value,
        })
      },
    },

    modelCondition: {
      get: function() {
        var allowedTypes = ['BOOLEAN', 'SELECT', 'DATE'];
        if (
          !this.selected_filters[this.filterData.name]?.condition
          && !allowedTypes.includes(this.selected_filters[this.filterData.name]?.type)
        ){
          return this.filterData.conditions[0]
        } else {
          return this.selected_filters[this.filterData.name]?.condition
        }
      },
      set: function(condition) {
        this.$store.dispatch(this.action_set_selected_filter_condition, {
          key: this.filterData.name,
          condition: condition,
        })
      },
    },
  },

  methods: {
    ...mapActions([
      'customDataSetSelectedFilterValue',
      'customDataSetSelectedFilterCondition',
    ]),

    getConditionValue(object) {
      return object?.value
    },

    returnFilterKeyOrLabel(item) {
      return item?.label ? item?.label : item.name
    },
  },

  watch: {
    arrayValues: function(afterVal) {
      this.modelValue = afterVal
    },

    //comments because on changing savedFilters cleared Values
    modelCondition: function(val) {
      if (val?.value === 'empty') {
        this.modelValue = 'empty'
      } else if (val?.value === 'active') {
        this.modelValue = 'active'
      }else if (val?.value === 'notActive') {
        this.modelValue = 'notActive'
      }
      // } else if (val?.value === 'between' || val?.value === 'notBetween') {
      //   this.arrayValues = []
      // } else {
      //   this.modelValue = null
      // }
    },
  },

}
</script>

<style scoped lang="scss">
.v-application--is-ltr .v-list-item__action:first-child {
    margin-right: 16px !important;
}
</style>
